@import '~antd/dist/antd.css';

.App {
  text-align: left;
}

.row {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
  margin: 30px;
}

.LogoWrapper {
  text-align: right;
  padding: 30px 30px;
}
  .LogoWrapper h1 {
  margin-top: 15px;
  color: #3d4e61;
  line-height: normal;
}


.LoginPage {
  float: left;
  width: 50%;
  height: 100%;
}

.row :after {
  content: "";
  display: table;
  clear: both;
}


  
.LoginForm {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  text-align: left;
  margin: 20px 0px;
  padding: 30px 30px;
  border-left: 1px solid black;
  position: relative;
}

.InputWrapper {
  margin-bottom: 15px;
}

.InputWrapper input {
  width: 100%;
  height: 42px;
  padding: 6px 10px;
  border: 1px solid #c1ccd9;
  border-radius: 4px;
}

.login-instructions h3 {
  color: #3d4e61;
  margin-bottom: 30px;
  font-size: 18px;
}

.userOptions {
  float: left;
  width: 33.33%;
  padding: 10px;
}

.col-span-12 {
  width: 40%;
}

.ant-modal {
  width: 50%;
}

.ant-btn {
  line-height: 2;
}

.isoInputFieldset {
  width: 100%;
  display: flex;
  margin-bottom: 35px;
}
.isoInputFieldset .vertical {
  flex-direction: column;
}


#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(0,0,0,0.2);
    z-index:9999;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.sb-info-label {
  width: 150px;
  float: left;
  font-weight: bold;
}

.sb-info-value {
  float:left;
}

.rightButton {
  float: right;
  margin-right: 10px;
}

.ant-tabs-bar {
  margin: 0px;
}

.caseTab {
  background-color: #fff;
  min-height: 500px;
  padding: 20px;
}

.hr {
  border-top: 1px solid #ccc;
  margin: 20px 0px;
}